@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Black.woff2") format("woff2"),
        url("../fonts/Poppins-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-BlackItalic.woff2") format("woff2"),
        url("../fonts/Poppins-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Bold.woff2") format("woff2"),
        url("../fonts/Poppins-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Italic.woff2") format("woff2"),
        url("../fonts/Poppins-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
        url("../fonts/Poppins-ExtraBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-MediumItalic.woff2") format("woff2"),
        url("../fonts/Poppins-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Medium.woff2") format("woff2"),
        url("../fonts/Poppins-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
        url("../fonts/Poppins-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
        url("../fonts/Poppins-ExtraLightItalic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-BoldItalic.woff2") format("woff2"),
        url("../fonts/Poppins-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Light.woff2") format("woff2"),
        url("../fonts/Poppins-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-LightItalic.woff2") format("woff2"),
        url("../fonts/Poppins-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
        url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Regular.woff2") format("woff2"),
        url("../fonts/Poppins-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-ThinItalic.woff2") format("woff2"),
        url("../fonts/Poppins-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-Thin.woff2") format("woff2"),
        url("../fonts/Poppins-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
        url("../fonts/Poppins-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src:
        url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
        url("../fonts/Poppins-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@import "./colors.scss";

:root {
    scroll-behavior: inherit;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    font-family: "Poppins";
}

.container-fluid,
.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.row {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.row > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

a {
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    text-decoration: none !important;
    color: inherit;
}

a:focus,
a:hover,
button:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

li,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

// common css start
.pdtb {
    padding-top: 10rem;
    padding-bottom: 10rem;
    @media (max-width: 1199px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    @media (max-width: 991px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
    @media (max-width: 575px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

.sub-heading {
    margin-bottom: 1rem;

    h2 {
        font-size: 6rem;
        font-weight: 600;
        color: $black-clr;
        margin-bottom: 3rem;

        @media (max-width: 1399px) {
            font-size: 3.5rem;
        }
        @media (max-width: 575px) {
            font-size: 2.8rem;
            margin-bottom: 2rem;
        }
    }

    p {
        font-size: 1.8rem;
        line-height: 3.2rem;
        margin-bottom: 2rem;
        @media (max-width: 575px) {
            font-size: 1.6rem;
            line-height: 2.8rem;
        }
    }
}

.banner-heading {
    h2 {
        font-size: 5rem;
        position: relative;
        letter-spacing: 6px;

        span {
            position: absolute;
            bottom: -1rem;
            height: 0.3rem;
            background-color: #000000;
            width: 24rem;
            left: 0;

            @media (max-width: 1399px) {
                width: 21rem;
            }
        }

        @media (max-width: 1399px) {
            font-size: 4rem;
        }

        @media (max-width: 1399px) {
            font-size: 3.5rem;
        }
    }
}

.theme-btn {
    min-width: 16rem;
    padding: 1.15rem 2rem;
    background-color: $primary-color !important;
    border: 1px solid $primary-color !important;
    color: $white-color !important;
    border-radius: 2.5rem;
    font-size: 1.8rem;
    display: inline-block;
    text-align: center;
    transition: 0.5s;
    outline: none;

    &:hover {
        background-color: #216217 !important;
        color: $white-color !important;
        border-color: #216217 !important;
    }
    &:active {
        background-color: #216217 !important;
        color: $white-color !important;
        border-color: #216217 !important;
    }
    &:focus {
        background-color: #216217 !important;
        color: $white-color !important;
        border-color: #216217 !important;
    }
    &:focus-visible {
        background-color: #216217 !important;
        color: $white-color !important;
        border-color: #216217 !important;
    }
}

.container {
    @media (min-width: 1400px) {
        max-width: 1340px;
    }
}

.custom-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media (min-width: 1400px) {
        max-width: 1340px;
    }
    @media (min-width: 1600px) {
        max-width: 1634px;
    }
}

// common css end

// header css

.th-header {
    // position: fixed;
    // width: 100%;
    // top: 0;
    // z-index: 999;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 5px 0;
    position: relative;
    z-index: 99;
    @media (max-width: 991px) {
        background-color: $white-color;
        box-shadow: rgb(0 0 0 / 25%) 0 0 5px 0;
        position: static;
    }

    .navbar {
        padding-top: 1rem;
        padding-bottom: 1rem;
        z-index: 9;
        @media (max-width: 991px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .container {
            max-width: 1634px;
            @media (max-width: 1599px) {
                max-width: 1340px;
            }
        }

        .navbar-brand {
            margin-right: 0;
            padding: 0;

            img {
                max-width: 10rem;
                transition: 0.3s;
                // filter: brightness(0) invert(1);

                @media (max-width: 1399px) {
                    max-width: 9rem;
                }
                @media (max-width: 991px) {
                    filter: none;
                }
            }
        }

        .nav-link {
            margin-right: 4.5rem;
            font-size: 1.8rem;
            color: #000;
            padding: 0;
            position: relative;
            @media (max-width: 1599px) {
                margin-left: 3.5rem;
                margin-right: 3.5rem;
                font-size: 1.6rem;
            }

            &.dropdown-toggle {
                &::after {
                    display: none !important;
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -0.2rem;
                height: 0.2rem;
                width: 0;
                background-color: #000;
                transition: 0.5s;
                @media (max-width: 991px) {
                    display: none;
                }
            }

            &.active {
                &::after {
                    width: 100%;
                }
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            @media (max-width: 1366px) {
                font-size: 1.6rem;
                margin-left: 3.5rem;
            }

            @media (max-width: 1199px) {
                margin-left:0rem;
            }
        }

        .dropdown-menu {
            padding: 0;
            border: none;
            box-shadow: 0 0 5px 0 #ccc;
            min-width: 25rem;
            top: 6rem;
            overflow: hidden;
            margin: 0;
            @media (max-width: 991px) {
                background-color: transparent;
                box-shadow: none;
                margin: 0;
            }

            .drp-dwn-ul {
                .drp-dwn-li {
                    position: relative;
                    .drp-dwn-link {
                        font-size: 1.6rem;
                        display: block;
                        border-bottom: 1px solid #f1f1f1;
                        color: #444;
                        padding: 1rem 2rem;

                        &:hover {
                            background-color: $primary-color;
                            color: #fff;
                        }
                        &.active {
                            background-color: $primary-color;
                            color: #fff;
                        }
                        @media (max-width: 991px) {
                            border-bottom: 1px solid rgba(225, 225, 225, 0.2) !important;
                            color: #fff;
                            padding-left: 5rem;
                        }
                    }
                    .drp-dwn-ul-innr {
                        position: absolute;
                        left: 80%;
                        top: 0;
                        background: #fff;
                        box-shadow: 0 0 5px 0px #ccc;
                        border-radius: 5px;
                        width: 310px;
                        opacity: 0;
                        visibility: hidden;
                        z-index: -1;
                        // transition: 0.1s;
                        li {
                            a {
                                font-size: 12px;
                                display: block;
                                // padding: 5px 5px;
                                padding: 1rem 1rem;
                                border-bottom: 1px solid #f1f1f1;
                                color: #444;

                                &:hover {
                                    background-color: #f1f1f1;
                                }
                            }
                        }
                    }

                    &:hover {
                        .drp-dwn-ul-innr {
                            left: 100%;
                            z-index: 9;
                            visibility: visible;
                        }
                    }
                    &:last-child {
                        .drp-dwn-link {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .theme-btn {
            width: calc(100% - 6rem);
            // margin-left: 3rem;
            @media(max-width:1399px){
                padding: 0.8rem 1.5rem;
            }
            @media (max-width: 991px) {
                margin-left: 1.7rem;
                margin-top: 2rem;
            }
        }
    }

    .navbar-toggler {
        border: none;
        z-index: 3;
        padding: 1.5rem 0.5rem;

        .navbar-toggler-icon {
            background-image: none;
            height: 0.4rem;
            width: 4rem;
            background-color: $secondary-color;
            position: relative;
            transition: all 0.2s cubic-bezier(0, 0, 1, 1) 0s;
            border-radius: 1.5rem;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 4rem;
                height: 0.4rem;
                background-color: $secondary-color;
                left: 0;
                border-radius: 1.5rem;
            }

            &:after {
                bottom: -1.1rem;
            }

            &:before {
                top: -1.1rem;
            }
        }

        &:focus {
            border: none;
            box-shadow: none;
        }

        &:not(.collapsed) {
            span {
                transform: rotate(45deg);
                background-color: #444;

                &:before {
                    transform: rotate(270deg);
                    top: 0;
                    background-color: #444;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .navbar-collapse {
        @media (max-width: 991px) {
            position: fixed;
            right: -32rem;
            top: 84px;
            background: #000f49;
            height: 100vh;
            padding-top: 3rem;
            padding-bottom: 1rem;
            width: 32rem;
            transition: 0.5s;
            overflow: hidden;
            overflow-y: auto;

            .navbar-nav {
                display: block;

                .nav-link {
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-left: 3rem;
                    padding-right: 3rem;
                    min-width: 25rem;
                    border-bottom: solid 1px rgba(225, 225, 225, 0.2);
                    color: $white-color;
                    font-size: 1.6rem;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                    margin: 0;
                }
            }
        }

        &.show {
            right: 0;
        }
    }

    &.is-sticky {
        // background-color: $white-color;
        // box-shadow: rgb(0 0 0 / 25%) 0 0 5px 0;
        position: fixed;
        width: 100%;
        top: 0rem;
        z-index: 999;
        animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
        // transition: 0.3s;
        @keyframes headerSlideDown {
            0% {
                transform: translateY(-15rem);
            }

            100% {
                transform: translateY(0);
            }
        }
        @media (max-width: 991px) {
            animation: none;
        }
        .navbar {
            padding-top: 1rem;
            padding-bottom: 1rem;

            .navbar-brand {
                img {
                    max-width: 10rem;
                    filter: none;
                }
            }
            .nav-link {
                color: $black-clr;
                @media (max-width: 991px) {
                    color: $white-color;
                }
                &:after {
                    background-color: $black-clr;
                }
            }
        }
    }
}

// header css end

// slick slider css start
.slick-slider {
    margin: 0;

    .slick-slide {
        & > div {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.hmg-banner-sl {
    position: relative;
    .slick-slider {
        position: static;
    }
    .slick-dots {
        max-width: 37px;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        padding: 20px 5px;
        background-color: #cccccc;
        border-radius: 20px;
        text-align: center;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width: 991px) {
            right: 0;
        }
        @media (max-width: 767px) {
            right: 50%;
            transform: rotate(90deg);
            top: 100%;
        }
        @media (max-width: 575px) {
           display: none!important;
        }


        li {
            width: 14px;
            margin: 5px 0;
            height: 14px;

            button {
                padding: 0;
                width: auto;
                color: #b9b9b9;
                &:before {
                    font-size: 14px;
                    width: 14px;
                    height: 14px;
                }
            }

            &.slick-active {
                button {
                    color: #000d20;
                    border-color: #000d20;

                    &::after {
                        color: #000d20;
                    }
                }
            }
        }
    }
}

// slick slider css end
.abutus-page {
    // padding-top: 10rem;
    // // padding-bottom: 8.5rem;
    // @media (max-width: 1399px) {
    //     padding-top: 8rem;
    //     // padding-bottom: 8rem;
    // }
    // @media (max-width: 1199px) {
    //     padding-top: 7rem;
    //     // padding-bottom: 7rem;
    // }
    // @media (max-width: 767px) {
    //     padding-top: 5rem;
    //     padding-bottom: 5rem;
    // }

    p {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 1.8rem;
        &:last-child {
            margin-bottom: 0;
        }
        @media (max-width: 1199px) {
            font-size: 1.6rem;
        }
        @media (max-width: 767px) {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    h3 {
        font-size: 22px;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    ul {
        margin-bottom: 30px;
        padding-left: 20px;

        li {
            font-size: 16px;
            margin-bottom: 15px;
            list-style-type: disclosure-closed;
        }
    }
}
.PhoneInputInput,
.PhoneInputInput:focus-visible,
.PhoneInputInput:focus {
    border: none !important;
    outline: none !important;
}
.PhoneInput {
    .PhoneInputCountry {
        &::after {
            content: "";
            position: absolute;
            right: -0.5rem;
            top: -1.8rem;
            width: 0.1rem;
            height: 5.4rem;
            background-color: #e5e5e5;
        }
    }
}

// Swal Input
.swal2-popup {
    width: 45rem;
    max-width: 100%;
}

.swal2-title {
    p {
        font-size: 2.4rem;
    }
}
.swal2-html-container {
    p {
        font-size: 1.6rem;
        margin-bottom: 0;
    }
}

.swal2-actions {
    button {
        min-width: 10rem;
        font-size: 1.8rem !important;
    }
}

.unlock-power {
    /* background-color: #f7f7f7; */
    .unlock-power-item {
        background-color: #f7f7f7;
        box-shadow: 0 0 5px 0px #ccc;
        margin-bottom: 1.8rem;
        @media (max-width: 991px) {
            margin-bottom: 0.1rem;
        }
        .unlock-power-item-img {
            img {
                width: 100%;
            }
        }
        .unlock-power-item-content {
            padding: 2rem 1.5rem;
            text-align: center;
            h4 {
                font-size: 2.4rem;
                font-weight: 600;
                color: #444;
                @media (max-width: 991px) {
                    font-size: 2rem;
                }
            }
            p {
                margin-bottom: 0;
                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
.unlock-power-slider {
    position: relative;
    .slick_prev {
        position: absolute;
        left: 0;
        background-color: transparent;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        font-size: 3.6rem;
        padding: 0;
        &:hover {
            i {
                color: $primary-color;
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
    .slick_next {
        position: absolute;
        right: 0;
        background-color: transparent;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        font-size: 3.6rem;
        padding: 0;
        &:hover {
            i {
                color: $primary-color;
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
}
/* Default slide */
.unlock-power .slick-slide {
    transform: scale(0.8);
    transition: all 0.4s ease-in-out;
    @media (max-width: 991px) {
        transform: scale(1);
    }
}
.unlock-power .slick-slide,
.unlock-power .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
    transform: scale(0.8, 0.8);
    transition: all 0.4s ease-in-out;
    @media (max-width: 991px) {
        transform: scale(1);
    }
}

/* Active center slide (You can change anything here for cenetr slide)*/
.unlock-power .slick-center,
.unlock-power .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
    transform: scale(1.1);
    @media (max-width: 991px) {
        transform: scale(1);
    }
    h4 {
        font-size: 2rem;
    }
    p {
        font-size: 1.4rem;
    }
}
.unlock-power .slick-current.slick-active {
    transform: scale(1.1);
    @media (max-width: 991px) {
        transform: scale(1);
    }
    p {
        font-size: 1.4rem;
    }
    h4 {
        font-size: 2rem;
    }
}

.wht-our-slider {
    overflow-x: hidden;

    .slick-slider {
        margin: 0 -1rem;

        .slick-list {
            overflow: visible;

            .slick-slide {
                opacity: 0.5;

                & > div {
                    margin-right: 3rem;
                    margin-left: 3rem;

                    @media (max-width: 1399px) {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    @media (max-width: 575px) {
                        margin: 0 0.5rem;
                    }
                }
            }

            .slick-active {
                opacity: 1;
            }
        }
    }
}
.wht-our-slider .slick-slider .slick-list .slick-slide > div > div > div > div {
    background-color: transparent;
    img {
        display: none;
    }
    ul {
        display: none;
    }
}
.wht-our-slider .slick-slider .slick-list .slick-slide.slick-active.slick-center.slick-current > div > div > div > div {
    // background-color: #fff;
    background-color: #daebe2;
    img {
        display: block;
    }
    ul {
        display: block;
    }
}
.wht-our-slider h2 {
    text-transform: uppercase;
    -webkit-text-stroke: 1px #000000;
    span {
        -webkit-text-stroke: 1px #000000;
        color: #f2f3f5;
    }
}

/*27-10-2023*/
    .modal-read-more-btn {
        margin-left: 2rem;
        padding: 1.2rem 1.5rem !important;
        @media(max-width:575px){
            font-size: 1.4rem;
            padding: 1rem 1rem !important;
        }
    }

.about-company-model {
    @media(max-width:575px){
        padding-left: 0!important;
    }
    .modal-header {
        border: none;
        .btn-close{
            position: absolute;
            width: 5rem;
            height: 5rem;
            padding: 0;
            top: 0;
            background: #3365a5;
            color: #fff;
            opacity: 1;
            border-radius: 50%;
            font-size: 3rem;
            top: -15px;
            right: -15px;
            line-height: 2rem;
            @media(max-width:575px){
                font-size: 2rem;
                width: 3rem;
                height: 3rem;
                top: 5px;
                right: 5px;
                border-radius: 0;
            }
        }
    }
    .modal-body{
        padding: 1.5rem 3rem 2.5rem 3rem;
        .about-company-card{
            h2{
                font-size: 3.2rem;
                margin-bottom: 2rem;
                font-weight: 600;
                color: $black-clr;
                @media(max-width:991px){
                    font-size: 2.5rem;
                }
                @media(max-width:575px){
                    font-size: 2rem;
                    margin-bottom: 1.5rem;
                }
            }
            p{
                font-size: 1.6rem;
                line-height: 2;
                color: #777777;
                @media(max-width:575px){
                    font-size: 1.4rem;
                }
            }
            ul{
                li{
                    margin-top: 1.5rem;
                    position: relative;
                    padding-left: 2rem;
                    font-size: 1.5rem;
                    color: #777777;
                    &:before{
                        content: "";
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 8px;
                        height: 8px;
                        background-color: $primary-color;
                        border-radius: 50%;
                    }
                }
            }
        }
        @media(max-width:991px){
            padding: 1.5rem 1.5rem 2rem 1.5rem;
        }
    }
}
